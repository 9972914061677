
import {Component, Prop, Watch} from 'vue-property-decorator';
import ViewConfigurator from "@/components/ViewConfigurator.vue";
import MegaTableAts from "@/components/ats/MegaTableAts.vue";
import {IBusinessSectorDB, IHeader, IUserDB, StoreAction} from "@/types";
import {Action, Getter, State} from "vuex-class";
import {mixins} from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import Visualisations from "@/components/Visualisations.vue";
import moment from "moment-timezone";
import clonedeep from "lodash.clonedeep";
import TitleManager from "@/mixins/TitleManager.vue";
import { ROUTES } from '@/data';

@Component({
    name: "Global",
    components: {Visualisations, MissionTile, MegaTableAts, ViewConfigurator}
})
export default class Global extends mixins(PageMixin, TitleManager) {
    @Prop() filters!: any;
    pageTitle = this.$t('page.atsFollowing.global.title');
    loading = false;
    candidates: any = [];
    filteredCandidates: any = [];
    timeout:any = 0;
    visualisations: any = {
        dateRange: {
            from: moment().startOf('week').valueOf(),
            to: moment().endOf('week').valueOf(),
        },
        mode: 'week'
    }

    missionAlert = "";

    @Action('actions/getCandidatesByStatus') getCandidatesByStatus!: StoreAction;
    @Action('actions/getArchivedMissions') getArchivedMissions!: StoreAction;

    @Getter('isArchives') isArchives!: boolean;
    @Getter('isAgency') isAgency!: boolean;

    @State('selectedCollaborators') selectedCollaborators!: IUserDB[];
    @State('selectedDomains') selectedDomains!: IBusinessSectorDB[];

    @Watch('selectedCollaborators', {deep: true})
    onCollabs() {
        this.timedSearch();
    }

    @Watch('selectedDomains', {deep: true})
    onDomains() {
        this.timedSearch();
    }

    @Watch('filters', {deep: true})
    onFilters() {
        console.log("filters");
        this.timedSearch();
    }

    @Watch('visualisations', {deep: true})
    onVisualisations() {
        console.log("visualisations");
        this.timedSearch();
    }

    @Watch('isArchives')
    onArchive() {
        this.timedSearch();
    }

    headerCandidateNumbers(filteredCandidates: any) {
        const candidates = filteredCandidates.candidates
        return candidates.length > 0 ? `${candidates.length}` : '';
    }

    candidateHeaderLabel(name: string) {
        if (this.$route.name === ROUTES.ATS.JOBOFFER.JOB) {
            return this.$t(`page.jobOffer.following.headers.${name}`);
        }
        return this.$t(`page.atsFollowing.global.headers.${name}`);
    }

    headerClass(name: string) {
        switch(name) {
            case "new":
                return 'new';
            case "interesting":
                return 'to-meet';
            case "meeting":
                return 'interview';
            case "met":
                return 'met';
            /*
            NOT USED FOR NOW
            See story 15095 : Masquer la colonne intégrés sur le suivis global
            */
            // case "integrated":
            //     return 'integrate';
            case "rejected":
                return 'rejected';
        }
    }

    get headers(): IHeader[] {
        return this.filteredCandidates.map((header:any) => {
            return {
                    label : `${this.candidateHeaderLabel(header.id)}`,
                    class : this.headerClass(header.id),
                    number: `${this.headerCandidateNumbers(header)}`
                }
        });
    }

    get rows() {
        return this.filteredCandidates?.map((candidatesbyStatus:any) => candidatesbyStatus.candidates) || [];
    }

    get ranges() {
        let fromDate, toDate;

        if (typeof this.visualisations.dateRange.from === 'number') {
            fromDate = this.visualisations.dateRange.from;
            toDate = this.visualisations.dateRange.to;
        } else {
            fromDate = this.visualisations.dateRange.from.valueOf();
            toDate = this.visualisations.dateRange.to.valueOf();
        }

        return {
            from: fromDate,
            to: toDate
        }
    }

    registerView(modelName: string) {
        this.$emit('registerView', {
            modelName,
            filters: {
                ...this.filters,
                missionAlert: this.missionAlert ? this.missionAlert : undefined
            },
        });
    }

    eraseView(selectedView: any) {
        this.$emit('eraseView', {
            nameSlug: selectedView.nameSlug,
            selectedView,
            filters: {
                ...this.filters,
                missionAlert: this.missionAlert ? this.missionAlert : undefined
            },
        });
    }

    timedSearch() {
        console.log("timedSearch");
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init();
        }, 300);
    }

    async init() {
        this.loading = true;
        try {
            this.candidates = await this.getCandidatesByStatus(this.filters);
            this.filteredCandidates = clonedeep(this.candidates);
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    candidateChanged() {
        this.timedSearch();
    }

    beforeMount() {
        this.$emit('showHideAddCandidateButton', true)
        this.timedSearch();
    }
}
